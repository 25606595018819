import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
} from "react-router-dom"

import ScheduleCounselingPage from "./components/ScheduleCounselingPage"
import NewCounselingPage from "./components/NewCounselingPage"
import ScheduleCustomerCounselingPage from "./components/ScheduleCustomerCounselingPage"
import NewCustomerCounselingPage from "./components/NewCustomerCounselingPage"
import ThanksCounselingAppointmentPage from "./components/ThanksCounselingAppointmentPage"
import ScheduleCustomerMedicalExaminationPage from "./components/ScheduleCustomerMedicalExaminationPage"
import NewCustomerMedicalExaminationPage from "./components/NewCustomerMedicalExaminationPage"
import ScheduleCustomerIntermediateMedicalExaminationPage from "./components/ScheduleCustomerIntermediateMedicalExaminationPage"
import NewCustomerUnableToPrescribeMedicalExaminationPage from "./components/NewCustomerUnableToPrescribeMedicalExaminationPage"
import ScheduleCustomerUnableToPrescribeMedicalExaminationPage from "./components/ScheduleCustomerUnableToPrescribeMedicalExaminationPage"
import NewCustomerIntermediateMedicalExaminationPage from "./components/NewCustomerIntermediateMedicalExaminationPage"
import ThanksMedicalExaminationAppointmentPage from "./components/ThanksMedicalExaminationAppointmentPage"
import ThanksIntermediateMedicalExaminationAppointmentPage from "./components/ThanksIntermediateMedicalExaminationAppointmentPage"
import ThanksWithdrawalInterviewPage from "./components/ThanksWithdrawalInterviewPage"
import PrescriptionPage from "./components/PrescriptionPage/Prescription"
import TrialPrescriptionPage from "./components/PrescriptionPage/TrialPrescription"
import Header from "./components/Header"
import Footer from "./components/Footer"
import multipayment from "./utils/multipayment"
import OverwriteMedicalExaminationPage from "./components/OverwriteMedicalExaminationPage"
import OverwriteMedicalExaminationConfirmPage from "./components/OverwriteMedicalExaminationConfirmPage"
import OverwriteIntermediateMedicalExaminationPage from "./components/OverwriteIntermediateMedicalExaminationPage"
import OverwriteIntermediateMedicalExaminationConfirmPage from "./components/OverwriteIntermediateMedicalExaminationConfirmPage"
import OverwriteUnableToPrescribeMedicalExaminationPage from "./components/OverwriteUnableToPrescribeMedicalExaminationPage"
import OverwriteUnableToPrescribeMedicalExaminationConfirmPage from "./components/OverwriteUnableToPrescribeMedicalExaminationConfirmPage"
import OverwriteCounselingPage from "./components/OverwriteCounselingPage"
import OverwriteCounselingConfirmPage from "./components/OverwriteCounselingConfirmPage"
import PrescriptionReceiptPage from "./components/PrescriptionReceiptPage"
import CounselingSurveyPage from "./components/CounselingSurveyPage"
import MedicalExamSurveyPage from "./components/MedicalExamSurveyPage"
import MonthlySurveyPage from "./components/WebInterviewPage/MonthlySurvey"
import ThanksSurveyPage from "./components/ThanksSurveyPage"
import ScheduleCustomerSideEffectExaminationPage from "./components/ScheduleCustomerSideEffectExaminationPage"
import NewCustomerSideEffectExaminationPage from "./components/NewCustomerSideEffectExaminationPage"
import OverwriteSideEffectExaminationPage from "./components/OverwriteSideEffectExaminationPage"
import OverwriteSideEffectExaminationConfirmPage from "./components/OverwriteSideEffectExaminationConfirmPage"
import WebInterviewPage from "./components/WebInterviewPage/Counseling"
import ThanksWebInterviewPage from "./components/ThanksWebInterviewPage"
import MedicalExaminationWebInterviewPage from "./components/WebInterviewPage/MedicalExamination"
import IntermediateMedicalExaminationWebInterviewPage from "./components/WebInterviewPage/IntermediateMedicalExamination"
import FollowupPage from "./components/WebInterviewPage/Followup"
import ThanksFollowupPage from "./components/ThanksFollowupPage"
import WithdrawalInterviewPage from "./components/WebInterviewPage/WithdrawalInterview"
import FirstPurchaseSurveyPage from "./components/WebInterviewPage/FirstPurchaseSurvey"
import ThanksFirstPurchaseSurveyPage from "./components/ThanksWithdrawalInterviewPage"
import ScheduleCustomerImmediateMedicalExaminationPage from "./components/ScheduleCustomerImmediateMedicalExaminationPage"
import OverwriteImmediateMedicalExaminationPage from "./components/OverwriteImmediateMedicalExaminationPage"
import OverwriteImmediateMedicalExaminationConfirmPage from "./components/OverwriteImmediateMedicalExaminationConfirmPage"
import FirstExaminationInputPage from "./components/FirstExaminationInputPage"
import ScheduleCustomerImmediateCounselingPage from "./components/ScheduleCustomerImmediateCounselingPage"
import NewCustomerImmediateCounselingPage from "./components/NewCustomerImmediateCounselingPage"
import NewCustomerImmediateMedicalExaminationPage from "./components/NewCustomerImmediateMedicalExaminationPage"
import ThanksImmediateMedicalExaminationAppointmentPage from "./components/ThanksImmediateMedicalExaminationAppointmentPage"
import ImmediateMedicalExaminationWebInterviewPage from "./components/WebInterviewPage/ImmediateMedicalExamination"
import FirstRegisterCounselingPage from "./components/FirstRegisterCounselingPage"
import FciExistingCustomer from "./components/FciExistingCustomer"
import FciWebInterviewPage from "./components/WebInterviewPage/FciCounseling"
import StepDay1 from "./components/StepDay1"
import StepDay2 from "./components/StepDay2"
import StepDay3 from "./components/StepDay3"
import StepDay4 from "./components/StepDay4"
import StepDay5 from "./components/StepDay5"
import StepDay6 from "./components/StepDay6"
import StepDay7 from "./components/StepDay7"
import StepDay8 from "./components/StepDay8"
import StepWeek2 from "./components/StepWeek2"
import StepWeek3 from "./components/StepWeek3"
import StepWeek4 from "./components/StepWeek4"
import StepWeek5 from "./components/StepWeek5"
import StepWeek6 from "./components/StepWeek6"
import StepWeek7 from "./components/StepWeek7"
import StepWeek8 from "./components/StepWeek8"
import StepWeek9 from "./components/StepWeek9"
import StepWeek10 from "./components/StepWeek10"
import StepWeek11 from "./components/StepWeek11"
import StepWeek12 from "./components/StepWeek12"
import StepWeek13 from "./components/StepWeek13"

import ScrollToTop from "../utils/ScrollToTop"
import { Sex } from "./components/WebInterviewPage/types"

type Props = {
  config: {
    gmoPgShopId: string
  }
}

const searchParams = new URLSearchParams(window.location.search)
const Pages = (
  <Switch>
    <Route
      exact
      path="/schedule_counselings"
      component={ScheduleCounselingPage}
    />
    <Route
      exact
      path="/schedule_counselings/:datetime"
      component={NewCounselingPage}
    />
    <Route
      exact
      path="/customer_schedule_counselings/:customerId"
      component={ScheduleCustomerCounselingPage}
    />
    <Route
      exact
      path="/customer_schedule_counselings/:customerId/:datetime"
      component={NewCustomerCounselingPage}
    />
    <Route
      exact
      path="/thanks_counseling_appointment/:counselingId"
      component={ThanksCounselingAppointmentPage}
    />
    <Route exact path="/thanks_counseling_appointment/:starttime/:counselingId">
      <Redirect
        to={`/thanks_counseling_appointment/${
          window.location.pathname.split("/")[3]
        }?start_at=${window.location.pathname.split("/")[2]}`}
      />
    </Route>
    <Route
      exact
      path="/schedule_medical_examination/:customerId"
      component={ScheduleCustomerMedicalExaminationPage}
    />
    <Route
      exact
      path="/schedule_medical_examination/:customerId/:datetime"
      component={NewCustomerMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_first_medical_examinations/:customerId"
      component={ScheduleCustomerMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_first_medical_examinations/:customerId/:datetime"
      component={NewCustomerMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_medical_examinations/:customerId"
      component={ScheduleCustomerMedicalExaminationPage}
    >
      {!searchParams.get("medicalExaminationId") && (
        <Redirect
          to={
            `/medical_examination_web_interview/` +
            window.location.pathname.split("/")[2]
          }
        />
      )}
    </Route>
    <Route
      exact
      path="/customer_schedule_medical_examinations/:customerId/:datetime"
      component={NewCustomerMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_intermediate_medical_examinations/:customerId"
      component={ScheduleCustomerIntermediateMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_intermediate_medical_examinations/:customerId/:datetime"
      component={NewCustomerIntermediateMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_un_medical_examinations/:customerId"
      component={ScheduleCustomerUnableToPrescribeMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_un_medical_examinations/:customerId/:datetime"
      component={NewCustomerUnableToPrescribeMedicalExaminationPage}
    />
    {/* 診療予約変更 */}
    <Route
      exact
      path="/customer_schedule_medical_examinations/overwrite/:medicalExaminationId/:customerId"
      component={OverwriteMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_medical_examinations/overwrite/:medicalExaminationId/:customerId/:datetime"
      component={OverwriteMedicalExaminationConfirmPage}
    />
    <Route
      exact
      path="/customer_schedule_intermediate_medical_examinations/overwrite/:intermediateMedicalExaminationId/:customerId"
      component={OverwriteIntermediateMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_intermediate_medical_examinations/overwrite/:intermediateMedicalExaminationId/:customerId/:datetime"
      component={OverwriteIntermediateMedicalExaminationConfirmPage}
    />
    <Route
      exact
      path="/customer_schedule_un_medical_examinations/overwrite/:unableToPrescribeMedicalExaminationId/:customerId"
      component={OverwriteUnableToPrescribeMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_un_medical_examinations/overwrite/:unableToPrescribeMedicalExaminationId/:customerId/:datetime"
      component={OverwriteUnableToPrescribeMedicalExaminationConfirmPage}
    />
    {/* カウンセリング予約変更 */}
    <Route
      exact
      path="/customer_schedule_counselings/overwrite/:counselingId/:customerId"
      component={OverwriteCounselingPage}
    />
    <Route
      exact
      path="/customer_schedule_counselings/overwrite/:counselingId/:customerId/:datetime"
      component={OverwriteCounselingConfirmPage}
    />
    <Route
      exact
      path="/thanks_medical_examination_appointment/:starttime"
      component={ThanksMedicalExaminationAppointmentPage}
    />
    <Route
      exact
      path="/thanks_intermediate_medical_examination_appointment/:starttime"
      component={ThanksIntermediateMedicalExaminationAppointmentPage}
    />
    <Route exact path="/prescriptions/:key" component={PrescriptionPage} />
    <Route
      exact
      path="/prescriptions/:key/trial"
      component={TrialPrescriptionPage}
    />
    <Route
      exact
      path="/prescription_receipt/:prescriptionKey"
      component={PrescriptionReceiptPage}
    ></Route>
    {/* アンケートと送信後画面 */}
    <Route
      exact
      path="/counseling_survey/:counselingId"
      component={CounselingSurveyPage}
    />
    <Route
      exact
      path="/medical_exam_survey/:medicalExaminationId"
      component={MedicalExamSurveyPage}
    />
    <Route
      exact
      path="/monthly_survey/:prescriptionId"
      component={MonthlySurveyPage}
    />
    <Route exact path="/thanks_survey" component={ThanksSurveyPage} />
    {/* 副作用予約 */}
    <Route
      exact
      path="/customer_schedule_side_effect_examinations/:customerId"
      component={ScheduleCustomerSideEffectExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_side_effect_examinations/:customerId/:datetime"
      component={NewCustomerSideEffectExaminationPage}
    />
    {/* 副作用予約変更 */}
    <Route
      exact
      path="/customer_schedule_side_effect_examinations/overwrite/:sideEffectExaminationId/:customerId"
      component={OverwriteSideEffectExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_side_effect_examinations/overwrite/:sideEffectExaminationId/:customerId/:datetime"
      component={OverwriteSideEffectExaminationConfirmPage}
    />
    {/* WEB問診と送信後画面 */}
    <Route
      exact
      path="/web_interview/:counselingId"
      component={WebInterviewPage}
    />
    {/*即時用WEB問診:LPからアクセス時のダイレクト予約時用*/}
    <Route
      exact
      path="/immediate_medical_examination_web_interview/:customerId"
      component={ImmediateMedicalExaminationWebInterviewPage}
    />
    {/* FCI用WEB問診と送信後画面 */}
    <Route
      exact
      path="/fci_web_interview/:counselingId"
      component={FciWebInterviewPage}
    />
    <Route
      exact
      path="/thanks_web_interview"
      render={(routeProps: RouteComponentProps) => (
        <>
          <ScrollToTop />
          <ThanksWebInterviewPage
            {...(routeProps.location.state as { sex: Sex })}
          />
        </>
      )}
    />
    <Route
      exact
      path="/medical_examination_web_interview/:customerId"
      component={MedicalExaminationWebInterviewPage}
    />
    <Route
      exact
      path="/intermediate_medical_examination_web_interview/:customerId"
      component={IntermediateMedicalExaminationWebInterviewPage}
    />
    <Route exact path="/followup/:customerId" component={FollowupPage} />
    <Route exact path="/thanks_followup" component={ThanksFollowupPage} />
    <Route
      exact
      path="/withdrawal_interview/:customerId"
      component={WithdrawalInterviewPage}
    />
    <Route
      exact
      path="/thanks_withdrawal_interview"
      component={ThanksWithdrawalInterviewPage}
    />
    {/* 即時診療予約 */}
    <Route
      exact
      path="/customer_schedule_first_immediate_medical_examinations/:customerId"
      component={ScheduleCustomerImmediateMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_first_immediate_medical_examinations/:customerId/:datetime"
      component={NewCustomerImmediateMedicalExaminationPage}
    />
    <Route
      exact
      path="/thanks_immediate_medical_examination_appointment/:starttime"
      component={ThanksImmediateMedicalExaminationAppointmentPage}
    />
    {/* 即時診療予約変更 */}
    <Route
      exact
      path="/customer_schedule_first_immediate_medical_examinations/overwrite/:medicalExaminationId/:customerId"
      component={OverwriteImmediateMedicalExaminationPage}
    />
    <Route
      exact
      path="/customer_schedule_first_immediate_medical_examinations/overwrite/:medicalExaminationId/:customerId/:datetime"
      component={OverwriteImmediateMedicalExaminationConfirmPage}
    />
    {/* 即時予約用カウンセリング */}
    <Route
      exact
      path="/customer_schedule_first_immediate_counselings/:customerId"
      component={ScheduleCustomerImmediateCounselingPage}
    />
    <Route
      exact
      path="/customer_schedule_first_immediate_counselings/:customerId/:datetime"
      component={NewCustomerImmediateCounselingPage}
    />
    {/* LPのiframe内に最初に表示されるページ */}
    <Route
      exact
      path="/first_examination_input"
      component={FirstExaminationInputPage}
    />
    {/* LPのiframe内に最初に表示されるページ。カウンセリングのみ。ABテスト用*/}
    <Route
      exact
      path="/first_register_counseling"
      component={FirstRegisterCounselingPage}
    />
    <Route
      exact
      path="/first_purchase_survey/:customerId"
      component={FirstPurchaseSurveyPage}
    />
    <Route
      exact
      path="/thanks_first_purchase_survey"
      component={ThanksFirstPurchaseSurveyPage}
    />
    {/* 既存客のfci申し込み時表示ページ */}
    <Route
      exact
      path="/fci_existing_customer"
      component={FciExistingCustomer}
    />
    <Route
      exact
      path="/step_day_1"
      component={StepDay1}
    />
    <Route
      exact
      path="/step_day_2"
      component={StepDay2}
    />
    <Route
      exact
      path="/step_day_3"
      component={StepDay3}
    />
    <Route
      exact
      path="/step_day_4"
      component={StepDay4}
    />
    <Route
      exact
      path="/step_day_5"
      component={StepDay5}
    />
    <Route
      exact
      path="/step_day_6"
      component={StepDay6}
    />
    <Route
      exact
      path="/step_day_7"
      component={StepDay7}
    />
    <Route
      exact
      path="/step_day_8"
      component={StepDay8}
    />
    <Route
      exact
      path="/step_week_2"
      component={StepWeek2}
    />
    <Route
      exact
      path="/step_week_3"
      component={StepWeek3}
    />
    <Route
      exact
      path="/step_week_4"
      component={StepWeek4}
    />
    <Route
      exact
      path="/step_week_5"
      component={StepWeek5}
    />
    <Route
      exact
      path="/step_week_6"
      component={StepWeek6}
    />
    <Route
      exact
      path="/step_week_7"
      component={StepWeek7}
    />
    <Route
      exact
      path="/step_week_8"
      component={StepWeek8}
    />
    <Route
      exact
      path="/step_week_9"
      component={StepWeek9}
    />
    <Route
      exact
      path="/step_week_10"
      component={StepWeek10}
    />
    <Route
      exact
      path="/step_week_11"
      component={StepWeek11}
    />
    <Route
      exact
      path="/step_week_12"
      component={StepWeek12}
    />
    <Route
      exact
      path="/step_week_13"
      component={StepWeek13}
    />
  </Switch>
)

const App: React.FC<Props> = (props) => {
  const { config } = props

  multipayment.init(config.gmoPgShopId)

  return (
    <Router>
      {!searchParams.get("hidden_header") && <Header />}
      {searchParams.get("longer_size") ? (
        <div className="longer-size">{Pages}</div>
      ) : (
        <main className="container">{Pages}</main>
      )}
      {!searchParams.get("hidden_header") && <Footer />}
    </Router>
  )
}

export default App
