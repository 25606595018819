import React , { useEffect } from "react"
import { customerLineOpen } from "../../../components/api-helpers/APICalls"

const StepDay6 = () => {
  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const customerId = query.get("customer_id")
    const notificationType = query.get("notification_type")
    if (customerId && notificationType) {
      customerLineOpen(customerId, notificationType)
    }
  }, [])

  return (
    <div>day6</div>
  )
}

export default StepDay6
